.project {  
    &.--soon {
        color: var(--grey);
    }  

    img {
        border-radius: rem(4px);
        height: rem(25);
    }

    @media (max-width: $smartphone) {
        .stack,
        .image {
            display: none;
        }

        > *:nth-child(1) { flex-basis: rem(30); }
        > *:nth-child(2) { flex-basis: calc(100% - #{rem(40)}); }
        > *:nth-child(3) {
            flex-basis: 80%;
            padding-left: rem(40);
        }
        > *:nth-child(5) {
            flex-basis: 20%;
            text-align: right;
        }
    }

    @media (min-width: $smartphone) {
        > *:nth-child(1) { flex-basis: rem(60); }
        > *:nth-child(2) { flex-basis: calc(40% - #{rem(60)}); }
        > *:nth-child(3) { flex-basis: 35%; }
        > *:nth-child(4) {
            flex-basis: 15%;
            text-align: center;
        }
        > *:nth-child(5) {
            flex-basis: 10%;
            text-align: right;
        }
    }
}