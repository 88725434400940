.experience { 
    @media (max-width: $smartphone) {
        > *:nth-child(1) { flex-basis: rem(30); }
        > *:nth-child(2) { flex-basis: calc(50% - #{rem(40)}); }
        > *:nth-child(3) {
            flex-basis: 50%;
            text-align: right;
        }

        .year {
            font-size: var(--font-size-xsmall);
        }

        .name {
            margin-bottom: 0 !important;
        }
    }

    @media (min-width: $smartphone) {
        *:nth-child(1) { flex-basis: rem(40); }
        *:nth-child(2) { flex-basis: calc(60% - #{rem(40)}); }
        *:nth-child(3) {
            flex-basis: 40%;
            text-align: right;
        }
    }
}