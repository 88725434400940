.block-project-list {
    --padding-v: var(--padding-l);
    --padding-h: var(--padding-xs);
    --font-size: var(--font-size-base);
}

.block-project-list {
    color: var(--white);

    .content {
        padding: var(--padding-v) 0;
    }

    a {
        @include basic-a();
        color: inherit;
    }

    .info,
    .projects,
    .awards,
    .experiences {
        li:first-child {
            border-bottom: 1px solid var(--grey);
            margin-bottom: rem(2);
        }
    }

    .info,
    .awards,
    .experiences {
        margin-top: var(--padding-m);
    }

    .icon {
        transform: scale(1.4);
    }

    .line {
        @include font-sans();
        
        align-items: center;
        display: flex;
        font-size: var(--font-size);
        justify-content: flex-start;
        padding: var(--padding-xxxs) var(--padding-h);
        color: var(--white);
        border-radius: rem(4);
    }

    @media (max-width: $smartphone) {
        .line {
            justify-content: space-between;
            flex-wrap: wrap;
        }

        .client {
            font-size: var(--font-size-xsmall);
        }

        li:first-child > .line > *:nth-child(n + 3) {
            display: none;
        } 

        li:not(:first-child) {
            margin-bottom: var(--padding-xxxs);

            // .line > *:nth-child(2) {
            //     margin-bottom: var(--padding-xxxs);
            // } 
        }  

        li:first-child {
            margin-bottom: var(--padding-xxs);
        } 
        
        .projects ul:last-child li:first-child { display: none; }
    }            

    @media (min-width: $smartphone) {
        .content {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            .projects {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;
                width: 100%;
                // width: calc(60% - #{rem(10)});
            }

            // .column {
            //     width: calc(40% - #{rem(10)});
            // }

            .projects ul,
            .info,
            .awards,
            .experiences {
                width: calc(50% - #{rem(10)});
            }

            .line {
                height: rem(33);
            }

            @include isCursor() {
                li:not(:first-child) .line {
                    .icon, img {
                        opacity: 0;
                    }
    
                    &:not(.--soon):hover {
                        background-color: var(--grey);
                        color: var(--green);
                        // background-color: var(--green);
                        // color: var(--black);

                        .icon, img {
                            opacity: 1;
                        }
                    }
                }
            }
        }
    }
}
