:root {
    --white: #f5f5f5;
    --black: #191919;
    --grey: #4b4b4b;
    --base100: #f5f5f5;
    --greyLight15: #191919;
    --greyDark: #444444;
    --red: #E5322D;
    --green: #3cea43;

    --primary: var(--black);
    --secondary: var(--white);

    --assertive: #f742f2;
    --focus: #f742f2;
    --color-error: #ff0000;
    --color-sucess: #3cea43;
}
