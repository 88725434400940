#Preloader {
    --bg: #000000;
}

#Preloader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    
    @include z-index($z-index-loader);
    color: var(--color);
    background-color: var(--bg);
    
    align-items: center;
    flex-direction: column;
    display: flex;
    justify-content: center;

    svg {
        height: 274px;
        width: 1080px;
        fill: var(--color);
    }
}
