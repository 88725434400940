@keyframes glow {
    0% { color: var(--white); }
    20% { color: var(--green); }
    80% { color: var(--green); }
    100% { color: var(--white); }
}

#Header {
    --width: 100vw;
    --color: var(--white);
    --bg: transparent;
    --padding-v: var(--padding-xs);
    --padding-h: var(--padding-xs);
    --font-size: var(--font-size-base);
}

#Header {
    @include z-index($z-index-header);
    position: fixed;
    width: var(--width);
    height: var(--height);
    left: 0;
    top: 0;
    padding: var(--padding-v) var(--padding-h);

    color: var(--color);
    background-color: var(--bg);

    @include font-sans();
    font-size: var(--font-size);

    a {
        @include basic-a();
        color: inherit;
        display: block;
    }

    .ad {
        animation: glow 2s infinite;
        overflow: hidden;
    }

    @media (max-width: $smartphone) {
        div:last-child {
            .clock {
                margin-bottom: var(--padding-xxxs);
            }

            font-size: var(--font-size-small);
        }

        a:first-child {
            margin-bottom: var(--padding-xs);
        }
    }

    @media (min-width: $smartphone) {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .ad {
            width: rem(250);
        }

        > div:last-child {
            display: flex;
            flex-direction: row-reverse;
            justify-content: space-between;
            align-items: center;
            gap: var(--padding-xs);
        }
    }
}
