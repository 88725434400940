

@use "sass:math";

#Footer {
    --width: 100vw;
    --color: var(--white);
    --bg: transparent;
    --padding-v: var(--padding-xs);
    --padding-h: var(--padding-xs);
    --font-size: var(--font-size-base);
    
    @media (max-width: $smartphone) {
        --font-size: var(--font-size-xsmall);
        --padding-v: var(--padding-xxs);
    }
}

#Footer {
    @include z-index($z-index-header);
    position: fixed;
    width: var(--width);
    left: 0;
    bottom: 0;
    padding: var(--padding-v) var(--padding-h);

    display: flex;
    justify-content: space-between;
    align-items: center;

    color: var(--color);
    background-color: var(--bg);

    @include font-sans();
    font-size: var(--font-size);

    a {
        @include basic-a();
        color: inherit;
    }

    @media (max-width: $smartphone) {
        // flex-direction: column-reverse;

        // > * {
        //     width: 100%;
        // }

        .rrss {
            display: flex;
            justify-content: flex-end;
            gap: var(--padding-xxxs);
        }
    }

    @media (min-width: $smartphone) {
        a {
            margin-left: var(--padding-xs);
        }
        
        @include isCursor() {
            a:hover {
                color: var(--green);
            }
        }
    }
}
