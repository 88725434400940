.info { 
    @include font-sans();
    font-size: var(--font-size-base);
    
    p {
        margin: var(--padding-xs) 0;
        max-width: rem(690);
    }

    a {
        color: var(--green);
        text-decoration: underline;

        @include isCursor() {
            &:hover {
                color: var(--black);
                background-color: var(--green);
            }
        }

        @include isTouch() {
            color: var(--black);
            background-color: var(--green);
        }
    }

    li:not(:first-child) {
        padding: 0 
        var(--padding-xs);
    }
}