.award { 
    @media (max-width: $smartphone) {
        > *:nth-child(1) { flex-basis: rem(30); }
        > *:nth-child(2) { flex-basis: calc(100% - #{rem(40)}); }
        > *:nth-child(3) {
            flex-basis: 80%;
            padding-left: rem(40);
        }
        > *:nth-child(6) {
            flex-basis: 20%;
            text-align: right;
        }

        .project {
            font-size: var(--font-size-xsmall);
        }
    }

    @media (min-width: $smartphone) {
        *:nth-child(1) { flex-basis: rem(40); }
        *:nth-child(2) { flex-basis: 45%; }
        *:nth-child(3) { flex-basis: calc(45% - #{rem(40)}); }
        *:nth-child(4) {
            flex-basis: 10%;
            text-align: right;
        }
    }
}